export const months = [
  { label: 'JAN', value: 1 },
  { label: 'FEV', value: 2 },
  { label: 'MAR', value: 3 },
  { label: 'ABR', value: 4 },
  { label: 'MAI', value: 5 },
  { label: 'JUN', value: 6 },
  { label: 'JUL', value: 7 },
  { label: 'AGO', value: 8 },
  { label: 'SET', value: 9 },
  { label: 'OUT', value: 10 },
  { label: 'NOV', value: 11 },
  { label: 'DEZ', value: 12 },
];
