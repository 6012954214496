export default [
  {
    code: 1,
    fullName: 'Banco do Brasil S.A.',
  },
  {
    code: 104,
    fullName: 'CAIXA ECONOMICA FEDERAL',
  },
  {
    code: 77,
    fullName: 'Banco Inter',
  },
  {
    code: 341,
    fullName: 'ITAÚ UNIBANCO S.A.',
  },
  {
    code: 237,
    fullName: 'Banco Bradesco S.A.',
  },
  {
    code: 33,
    fullName: 'BANCO SANTANDER',
  },
  {
    code: 260,
    fullName: 'Nu Pagamentos S.A (Nubank)',
  },
  {
    code: 665,
    fullName: 'Neon Pagamentos S.A',
  },
];
